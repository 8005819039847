:root {
  --text-font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --primary-bg-color: #282c34;
  --secondary-bg-color: #575969;
}

.App {
  font-family: var(--text-font);
  text-align: center;
  background-color: var(--primary-bg-color);
  color: #fff;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
a {
  color: white;
}
header {
  display: none;
}
header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}
.menu button {
  background: none;
  border: none;
}
nav {
  display: none;
  background-color: #4c4f5f;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
}

.disabled {
  pointer-events: none;
}

.navItems {
  padding: 8px;
  background-color: #212122;
  width: 100%;
  max-width: 340px;
}
.navPromptWrapper {
  /* border-bottom: 1px solid #ffffff33; */ 
  /* padding: 10px; */
  height: calc(100vh - 330px);
  overflow-y: auto;
  overflow-x: hidden;
}
.navPrompt {
  display: flex;
  align-items: center;
  padding: 10px;
  /* column-gap: 10px; */
  margin: 5px 0;
  text-align: left;
  color: white;
  /* width:100%; */
  /* color:blue */
}
.navPrompt.selected {
  background-color: rgba(255, 255, 255, 0.1);
}


.navPrompt a {
  display: flex;
  align-items: center;
  /* column-gap: 10px; */
  text-decoration: none;
}
.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #ececf1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
  padding-left: 10px;
}
.navPrompt input {
  background-color: transparent; 
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
  padding-left: 10px;
  border: none; 
}
.iconContainer {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* width: 60px;  */
  height: 18px; 
  overflow: hidden;
  cursor: pointer; 
}
.iconClick{
  opacity: 0.5; 
  transition: opacity 0.2s ease; 
  
}

.iconClick:hover {
  opacity: 1; 
  background: none;
}

.iconClick:active {
  opacity: 1; 
}

.deleteBox{
  
  display: flex;
  align-items: center;
}
.deleteBox > * {
  padding: 0 5px;
}
.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}
#botMessage pre {
  padding-left: 24px;
  padding-top: 25px;
  overflow-x: auto;
  max-width: 800px;
  /* white-space: pre-wrap; */
  margin: 0;
  /* padding: 0; */
  text-indent: 0;
 
  position: relative;
  text-align: justify;
  top: -20px;
  margin-bottom: 10px;
  left: -24px;
}
/* Markdown table styling */

.markdown-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1em;
}
.markdown-table th,
.markdown-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}


pre {
  font-family: var(--text-font);
}
@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navCloseIcon {
  width: 20%;
  margin: 10px;
}
nav svg {
  float: left;
}

.sideMenu {
  width: 244px;
  padding: 8px;
  background-color: #202022;
}

.sideMenuButton {
  border: 1px solid hsl(0deg 0% 100% / 20%);
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}
.form-group {
  margin-bottom: 1rem;
}
.form-group::label {
  padding-bottom: 5px;
  padding-right: 100px;
}

.form-group textarea::placeholder {
  color: rgb(153, 151, 151);
}
.form-control {
  display: block;
  width: 99%;
  font-size: 1rem;
  line-height: 1.5;
  color:white;
  background-color: #4c4f5f;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.modal {
  display: flex; 
  flex-direction: column;
  /* align-items: center; */
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  /* background-color: #4c4f5f; */
  background-color: #444654;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 12px;
  position:  absolute;
  width: 450px;
  /* height: 250px; */
  top:calc(30% - 125px);
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  bottom: calc(30% - 125px);
  z-index: 1000;
  padding: 10px;
  color: white;
}
.form-control::placeholder{
  color:white
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25); 
  z-index: 500; 
}
.info-input {
  display: block; /* Make the div a block-level element */
  margin-bottom: 10px; /* Add some space between each block element */
}
.navPrompt:hover,
.sideMenuButton:hover {
  cursor: pointer;
  background-color: hsla(240, 9%, 59%, 0.1);
}
.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
}
.chatBox {
  flex: 1;
  background-color: #353540;
  position: relative;
  line-height: 24px;
  color: #d1d5db;
  font-size: 16px;
}
#introsection::before,
#introsection::after {
  content: "";
  float: left;
  color: rgb(0, 134, 244);
}
#introsection {
  text-align: left;
  padding: 20px;
}
#introsection h1 {
  padding-top: 20px;
  line-height: 33px;
  text-align: center;
  max-width: 800px; /* Set a max-width to maintain readability */
  margin: 0 auto; /* Horizontally center the element */
}


#introsection h2 {
  padding-top: 20px;
  line-height: 33px;
  text-align: left;
}

#introsection h3 {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  max-width: 800px; /* Set a max-width to maintain readability */
  margin: 0 auto; /* Horizontally center the element */
}

#introsection .centered-content {
  max-width: 800px;
  margin: 0 auto; /* Horizontally center the element */ 
}

#introsection ul {
  list-style-type: square;
}
#introsection pre {
  margin: 0;
  display: inline;
}
.chatLogWrapper {
  height: 80vh;
  overflow-y: auto;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

#chatPrompt {
  white-space: pre-line;
}

.chatPromptWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 25px;
}

.userSVG {
  transform: scale(0.6);
}

.botMessageMainContainer {
  width: 100%;
  background-color: #444654;
  position: relative;
  
}
.botMessageWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 25px;
  min-height: 10px;
}
.markdown-container {
  overflow-x: auto !important;
  width: 710px;

}

.inline-code {
  background-color: #000000;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
  
}

.block-code {
  display: block;
  background-color: #000000;
  padding: 10px;
  border-radius: 4px;
  font-family: monospace;
  white-space: pre-line;
}

.stop-message {
  z-index: 999;
  position: absolute !important;
  bottom: 10px;
  right: 50px;
  width: fit-content;
  min-width: 100px; /* Set a minimum width */
  max-width: 200px; /* Set a maximum width */
  width: auto; /* Allow the width to adjust based on the content */
  height: 35px; /* Set a fixed height */
  padding: 8px 12px;
  border-radius: 10px;
  border: 1px solid rgb(86, 88, 105);
  background-color: rgb(52, 53, 65);
  color: rgb(217, 217, 217);
  cursor: pointer;
  font-size: 15px;
  box-sizing: border-box; /* Include padding and border in the total width and height */
  text-align: center; /* Center the text within the button */
  white-space: nowrap; /* Ensure the button text stays on a single line */
}
.stop-button {
  z-index: 1000;
  /* position :absolute !important; */
  /* right: 0 !important; */
 
}
.stop-messgage:hover {
  background-color: rgb(64, 65, 79);
  border-radius: 10px;
  /* border: none; */
}
.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}
#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}
.clipboard {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

.autocomplete-suggestions{
  /* width: 80%;
  left: 50%;
  right: 50%; */
  /* bottom:0px; */
  /* transform: translate(-50%, -50%); */
  position: fixed;
  bottom: 5%;
  background-color: #41414e;
  width: 100%;
  /* display:flex; */
  /* align-items: end; */
  overflow-y: auto;
  max-height: 80px;
  place-items: end;
}
.suggestion-item {
  display: flex;
  align-items:flex-end; /* Vertically align items in the suggestion item */
  padding: 2px; /* Add padding to the suggestion items as needed */
  border-bottom: 1px solid #555; /* Add a border or separator between items */
}

/* Style for the last suggestion item, if necessary */
.suggestion-item:last-child {
  border-bottom: none; /* Remove the border for the last item */
}
.mainInputWrapper{
  top:9%;
  left: 50%;
  right: 50%;
  bottom: 10%;
  position: relative;
  transform: translate(-50%, -50%);
  width: 80%;
}
.selected-suggestion {
  background:#555564;
  cursor: pointer;
}

.inputPromptWrapper {
  /* margin-top: 0px; */
  
  /* bottom: 4%; */
  /* position: relative; */
  
  /* max-height: 200px; */
  position: fixed;
  width: 100%;
  height: 45px;
  background-color: #41414e;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  display: flex;
}

.inputPromptTextarea {
  padding: 10px;
  padding-right: 50px;
  flex: 1;
  resize: none;
  background-color: #41414e;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  overflow-y: auto; /* Updated from hidden to auto to allow scrolling */
  white-space: pre-wrap; /* Added to allow text to move to the next line */
  word-break: break-word; /* Added to allow long words to break to the next line */
  border-radius: 10px;
  max-height: 75px;
}
.promptButton{
  background: #292932;
  border-radius: 0%;
  color: rgb(255, 255, 255);
  height: 30px;
  border-radius: 10px;
}
.promptButton:hover {
  background:#545463;
  border-radius: 0%;
  color: rgb(255, 255, 255);
  border-radius: 10px;
}
form button {
  background: rgb(65, 65, 78);
  border: none;
  position: absolute;
  top: 20%;
  right: 15px;
  width: 35px;
  height: 45px;
  border-radius: 20%;
}

@media (hover: hover) {
  button:hover {
    cursor: pointer;
    background: #212023;
    border-radius: 20%;
  }
}

.loginContainer {
  background: var(--primary-bg-color);
  width: 100%;
  height: 100%;
  font-family: var(--text-font);
}

.loginContainerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.loginContainerContent svg {
  padding-bottom: 30px;
}

.loginContainer h1 {
  font-size: 30px;
  margin: 0;
}

.loginContainer p {
  font-size: 18px;
  line-height: 5px;
}

.loginButtonWrapper {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-top: 20px;
}

#loginButton {
  padding: 10px 25px;
  border-radius: 7px;
  color: #fff;
  background: #10a37f;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
#loginButton:hover {
  background-color: rgb(26, 127, 100);
}

@media screen and (min-width: 768px) {
  .inputPromptWrapper {
    position: absolute;
  }
}

@media screen and (max-width: 1024px) {
  .App {
    display: block;
  }
  header {
    display: flex;
    align-items: center;
    background: #353441;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    padding: 4px 0 0 12px;
  }
  nav {
    display: flex;
  }
  .sideMenu {
    display: none;
  }
  .chatBox {
    position: static;
  }
  /* .chatPromptWrapper {
    padding: 12px;
  }
  .botMessageWrapper {
    padding: 12px;
  } */

  .stop-messgage {
    right: 5px;
    font-size: 13px;
    padding: 8px 15px;
  }
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 16px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }
}

@media screen and (min-width: 1536px) {
  .loginContainer h1 {
    font-size: 40px;
  }
  .loginContainer p {
    font-size: 25px;
  }
  #loginButton {
    font-size: 22px;
  }
}

